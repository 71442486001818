import { httpGet, httpPost, httpPut } from './http';
import { init } from './config';

const API = {};
const PUT = 'put';
const POST = 'post';
const GET = 'get';

const urls = init();

const endpoints = {
  wrap_output: urls.base + 'output/wrapping/set',
  check_regcode: urls.order + 'regicode/',
  user_status: urls.rest + 'users/status',
  check_username: urls.base + 'users/username/check',
  old_reset_password: urls.login + 'users/password/reset',
  reset_password: urls.base + 'reset_password',
  login: urls.login + 'users/login',
  push_concurrent: urls.websocket + 'notification/push',
  record_login: urls.base + 'log/push',
  disable_cache: urls.base + 'output/caching/set',
  //  route_to_assessment: urls.base + 'route_to_assessment',
  route_to_interface: urls.base + 'route_to_interface',
  get_school_info_by_id: urls.base + 'schools/info/',
  check_username_or_email: urls.base + 'users/username/check',
  user_create: urls.login + 'users',
  add_to_account: urls.login + 'users/register',
  set_security_questions: urls.base + 'user/security/set/questions',
  reset_student_password: urls.rest + 'users/password/reset/noauth',
  get_trial_code: urls.trial + 'registration/code',
  get_trial_info: urls.trial + 'username'
};

API.wrap_output = function () {
  var url = endpoints.wrap_output;
  var params = { wrap_output: true };
  return httpPut(url, params);
}

API.user_status = function () {
  var url = endpoints.user_status;
  return httpGet(url);
}

API.check_username = function (username, password) {
  var url = endpoints.check_username;
  var params = { username, password };
  return httpPut(url, params);
}

API.check_regcode = function (code) {
  var url = endpoints.check_regcode + code;
  return httpGet(url, true);
}

API.reset_password = async function (payload) {
  var url = endpoints.reset_password;
  var params = payload;
  let result = [];
  try {
    result = await httpPost(url, params);
  } catch (e) {
  }
  return result;
}

API.changePasswordWithToken = function (payload) {
  var url = urls.login + 'users/password/token';
  return httpPost(url, payload);
}

API.login = function (email, pw) {
  var url = endpoints.login;
  var params = { email, pw, wrap_output: true };
  return httpPost(url, params)
    .then(res => res)
    .catch(err => { throw err; });
}

API.push_concurrent = function (user_id, session_id) {
  var url = endpoints.push_concurrent;
  var params = {
    user_id: 115250,
    listen_for: `conurrentSession.${session_id}`,
    scope: {
      users: {
        user_id: user_id
      }
    },
    context: {
      key: 'autologout',
      val: true
    }
  };
  console.log('API.push_concurrent', url, params);
  return httpPost(url, params)
    .then(res => res)
    .catch(err => { throw err; });
}

API.record_login = function () {
  var url = endpoints.record_login;

  var payload = {
    event_type: 'login',
    event_value: 'success',
    note: 'teacher app'
  };
  return httpPost(url, payload);
}

API.disable_cache = function () {
  var url = endpoints.disable_cache;
  return httpPut(url, { enable_cache: false });
}


// Accept URL for landing page--will end in 'index.html'--and append a query string
// with a random number. This should prevent caching. 21322
function cacheKiller(url) {
  const newUrl = url + '?' + Math.random().toString();
  return newUrl;
}

API.route_to_interface = function () {
  var url = endpoints.route_to_interface;
  return httpGet(url).then(res => {
    if (res === 'next') {
      //const preventCache = cacheKiller(urls.next);
      // The new directory everyone should go to. 17306
      // Append random number to landing page to prevent caching. 21322
      return { assessment_app: true, url: urls.next };

    } else if (res === 'next-react') {
      // This is to TEMPORARILY accommodate an individual user account.
      // A quick solution for 17763.
      return { assessment_app: true, url: urls.nextReact };

    } else if (res === 'canvas-ui') {
      // This is to route a Canvas "development" account, since we're in the really rotten situation of having to develop something
      // that's only on production: Canvas is fixed in where it routes, and Canvas is integral to our development.
      // On record: this edlink / broward / canvas integration was very poorly coordinated, with nothing remotely like sufficient time
      // for planning, thinking things through, developing, testing, &c.
      return { assessment_app: true, url: urls.canvasUi };

    } else {
      return { assessment_app: true, url: urls.default };
    }
  });
}

API.get_school_info_by_id = function (school_id) {
  var url = endpoints.get_school_info_by_id + school_id;
  return httpGet(url);
}

API.check_username_or_email = function (username) {
  var url = endpoints.check_username_or_email;
  var params = { username };
  return httpPut(url, params).then(res => { console.log('check_username_or_email response', res); return res; }, err => { console.log('ERR', err); });
}

API.user_create = function (payload) {
  var url = endpoints.user_create;
  return httpPost(url, payload);
}

API.add_to_account = function (payload) {
  var url = endpoints.add_to_account;
  return httpPost(url, payload);
}

API.set_security_questions = function (payload) {
  console.log('API.set_security_questions', payload);
  var url = endpoints.set_security_questions;
  return httpPut(url, payload);
}

API.reset_student_password = function (payload) {
  var url = endpoints.reset_student_password;
  return httpPut(url, payload);
}

API.get_trial_code = function (type) {
  var url = endpoints.get_trial_code;
  if (type !== 'math') url += '/' + type;
  return httpGet(url);
}

API.get_trial_info = function () {
  var url = endpoints.get_trial_info;
  return httpGet(url);
}


export { API };
